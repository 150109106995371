import React from "react";
import Spinner from "../../../components/Spinner";
import useUpdateDoc from "../../../hooks/useUpdateDoc";
import { formatCurrencyTable } from "../../../utils/formatCurrencyTable";

function TablePagos({ landing, loading, schedule, payment, data }) {
  const { handleUpdate } = useUpdateDoc();
  const handleUpdatePayment = (id) => {
    const existId = data?.payments?.find(
      (payment) => payment === id.toString()
    );

    if (existId) {
    } else {
      // Confirmación antes de insertar el pago
      const isConfirmed = window.confirm(
        "¿Está seguro de que desea insertar este pago? Una vez insertado, no se puede borrar."
      );

      if (isConfirmed) {
        // El usuario confirmó, insertamos el pago
        const insertArray = data?.payments.push(id.toString());

        const newArray = data?.payments;

        handleUpdate("requests", data.uuid, {
          payments: newArray,
        });
      }
    }
  };
  return (
    <table className=" w-full text-sm text-left rtl:text-right text-gray-500 overflow-hidden">
      <thead
        className={`${
          landing ? "bg-richBlack text-white" : "text-gray-700 bg-gray-50"
        } text-xs  uppercase overflow-hidden`}
      >
        <tr>
          <th className="px-6 py-3">Cuotas</th>
          <th className="px-6 py-3">Fecha de pago</th>
          <th className="px-6 py-3">Valor préstamo</th>
          <th className="px-6 py-3">Cuota a pagar</th>
          <th className="px-6 py-3">Capital</th>
          <th className="px-6 py-3">Interés</th>
          <th className="px-6 py-3">Saldo deuda</th>
          {payment && <th className="px-6 py-3">Pago</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr className="">
            <td colSpan="100%" className="text-center">
              <Spinner />
            </td>
          </tr>
        ) : (
          schedule.map((row, index) => {
            const ifPayment = data?.payments?.some(
              (el) => Number(el) === index + 1
            );

            return (
              <tr
                className={`${
                  landing ? "bg-richBlack text-white" : "bg-white"
                }  border-b`}
                key={row.cuota}
              >
                <td className="px-6 py-4">{row.cuota}</td>
                <td className="px-6 py-4">
                  {row.fechaPago === "Invalid Date" ? "" : row.fechaPago}
                </td>
                <td className="px-6 py-4">
                  ${formatCurrencyTable(row.valorPrestamo)}
                </td>
                <td className="px-6 py-4">
                  ${formatCurrencyTable(row.cuotaAPagar)}
                </td>
                <td className="px-6 py-4">
                  ${formatCurrencyTable(row.capital)}
                </td>
                <td className="px-6 py-4">
                  ${formatCurrencyTable(row.interes)}
                </td>
                <td className="px-6 py-4">
                  ${formatCurrencyTable(row.saldoDeuda)}
                </td>
                {payment && (
                  <td className="px-6 py-4">
                    <div
                      onClick={() => handleUpdatePayment(row.cuota)}
                      className={` rounded-full  w-5 h-5 border-gray-700 cursor-pointer ${
                        row.payment || ifPayment ? "bg-blue-700" : "border"
                      } `}
                    />
                  </td>
                )}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default TablePagos;
