import React, { useState } from "react";
import LogoWhite from "../LogoWhite";

import Form from "../Form";
import Features from "./features";
import FormCompany from "../FormCompany";
import FormCodeudor from "../FormCodeudor";
import { planPagos } from "../../utils/planPagos";
import { useSnapshot } from "../../hooks/useSnapshot";
import dayjs from "dayjs";
import TablePagos from "../../pages/plataforma/plan-de-pagos/table";
import { Link } from "react-router-dom";

function Banner({ company, codeudor, dataCodeudor }) {
  const { data: tasa, isFetching } = useSnapshot("tasa");

  const [sended, setSended] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    knowMoney: true,
    ammount: "10000000",
    month: "12",
    expedition: "",
    name: "",
    lastName: "",
    company: "",
    NIT: "",
    address: "",
    cellphone: "",
    identity: "",
    email: "",
  });

  const [files, setFiles] = useState({
    cert_laboral: "",
    comp_nomina: "",
    fot_cedula: "",
    dec_renta: "",
  });

  const { schedule, totalCredit, totalInterest } = planPagos({
    ...data,
    tasa: tasa[0]?.number,
    start_date: dayjs().format("YYYY-MM-DD"),
  });
  return (
    <section className="">
      <div className="flex items-center justify-between bg-orange py-6 px-6">
        <LogoWhite size={100} />
        <div className="absolute top-7 right-7 hidden md:block">
          <Link to="/plataforma">
            <button className="bg-richBlack rounded-lg px-3 py-1 text-white font-semibold">
              Portal <span className="">Administrador</span>
            </button>
          </Link>
        </div>
      </div>
      {step === 2 || sended ? (
        <div className="mt-0 p-10 bg-orange">
          <p className="mb-3 text-xl">
            {step === 2 ? (
              <div className="border-b border-gray-400 pb-3">
                <div className="text-white">
                  <p>Este sería tu crédito, deseas continuar?</p>
                  <div className="flex gap-3 items-center justify-center">
                    <button
                      className="bg-gray-200 rounded-lg px-3 py-1 text-sm text-black mt-2"
                      onClick={() => setStep(0)}
                    >
                      Volver
                    </button>
                    <button
                      className="bg-richBlack rounded-lg px-3 py-1 text-sm font-semibold text-white mt-2"
                      onClick={() => setStep(1)}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              "Solicitud enviada correctamente, este sería tu crédito:"
            )}
          </p>
          <p className="mb-4 text-white font-semibold">
            Tasa: {tasa[0]?.number} % anual
          </p>
          <div className="md:mx-0 -mx-6 overflow-hidden">
            <TablePagos landing loading={false} schedule={schedule} />
          </div>
        </div>
      ) : (
        <div className="bg-orange">
          <div className="" />
          <div className="max-w-xl mx-auto min-h-[88vh] pt-20">
            {company ? (
              <FormCompany />
            ) : codeudor ? (
              <FormCodeudor dataCodeudor={dataCodeudor} />
            ) : (
              <Form
                setSended={setSended}
                sended={sended}
                data={data}
                setData={setData}
                setFiles={setFiles}
                files={files}
                step={step}
                setStep={setStep}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Banner;
