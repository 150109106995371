import React from "react";

function Logo({ size }) {
  return (
    <div className="flex h-[44px] items-center relative -right-3 -bottom-3">
      <img
        src="/favicon_lendy_white.png"
        width={size ? 30 : 35}
        height={size ? 30 : 35}
        alt="logo lendy"
        className="object-contain mt-[9px] mr-6 "
        quality={100}
        layout="fixed"
      />
      <img
        src="/logo_lendy_white.png"
        width={size ? size : 35}
        height={size ? size : 35}
        alt="logo lendy"
        quality={100}
        className="object-contain"
        layout="fixed"
      />
    </div>
  );
}

export default Logo;
