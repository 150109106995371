import React, { useState } from "react";
import Table from "./components/Table.js";
import Modal from "../../components/Modal/index.js";
import Form from "../../components/Form/index.js";
import { useSnapshotWhere } from "../../hooks/useSnapshotWhere.js";
import Spinner from "../../components/Spinner/index.js";

function Platform({ user }) {
  const { data: dataUser, isFetching } = useSnapshotWhere("users", {
    filter: {
      firstCondition: "id",
      secondCondition: "==",
      thirdCondition: user.uid,
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [sended, setSended] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    knowMoney: true,
    ammount: "10000000",
    month: "12",
    expedition: "",
    name: "",
    lastName: "",
    company: "",
    NIT: "",
    address: "",
    cellphone: "",
    identity: "",
  });

  const [files, setFiles] = useState({
    cert_laboral: "",
    comp_nomina: "",
    fot_cedula: "",
    dec_renta: "",
  });

  return (
    <div>
      <Modal showModal={isOpen} setShowModal={() => setIsOpen(false)}>
        <Form
          setSended={setSended}
          sended={sended}
          data={data}
          setData={setData}
          setFiles={setFiles}
          files={files}
          step={step}
          setStep={setStep}
          admin
        />
      </Modal>
      <p className="text-xl font-bold mb-3 ">Solicitudes de crédito</p>
      <button
        className="bg-blue-500 rounded-lg px-3 py-1 mb-5 text-white"
        onClick={() => setIsOpen(true)}
      >
        Agregar
      </button>
      {isFetching ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <Table user={dataUser} isFetchingUser={isFetching} />
      )}
    </div>
  );
}

export default Platform;
