import React from "react";
import Input from "./Input";
import { formatNumber } from "../../utils/format";
import { formatCurrency } from "../../utils/formatCurrency";

function FormKnowMoney({ setStep, data, setData }) {
  const handleInputChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setData({ ...data, ammount: formattedValue });
  };

  return (
    <form className="w-full mx-auto bg-white bg-opacity-10 rounded-xl p-3 mt-3">
      {/* <p className="text-gray-200">
        La libranza se podrá realizar siempre y cuando como trabajador no
        recibas menos del 50% del neto de tu salario después de los descuentos
        de ley.
      </p> */}
      <div className="grid grid-cols-1 mt-3">
        <Input
          label="Monto Solicitado"
          placeholder="$0"
          type="text"
          onChange={(e) => handleInputChange(e)}
          value={data.ammount}
        />
      </div>

      <div className="grid grid-cols-1 mb-3">
        <Input
          label="¿A cuántos meses?"
          type="number"
          onChange={(e) => {
            setData({ ...data, month: e.target.value });
          }}
          value={data.month}
        />
      </div>

      <button
        onClick={() => setStep(2)}
        type="submit"
        className="text-white rounded-lg px-5 py-2.5 text-center bg-secondary w-full font-bold text-xl"
      >
        Siguiente
      </button>
    </form>
  );
}

export default FormKnowMoney;
