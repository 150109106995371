import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export function planPagos(data) {
  // Variables principales
  const loanAmount = Number(data.ammount.replace(/\./g, "")); // Monto del préstamo
  const annualInterestRate = data.tasa / 100; // Tasa de interés anual en decimal
  const monthlyInterestRate = annualInterestRate / 12; // Tasa de interés mensual
  const loanTermMonths = data.month; // Plazo del préstamo en meses
  const startDate = dayjs(data.start_date, "YYYY-MM-DD"); // Fecha de inicio del préstamo

  // Cálculo de la cuota mensual usando la fórmula de pago de una anualidad
  const monthlyPayment =
    loanAmount *
    (monthlyInterestRate /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths)));

  // Función para generar el plan de pagos
  const generatePaymentSchedule = () => {
    let balance = loanAmount;
    const schedule = [];
    let totalInterest = 0; // Acumulador para el total de intereses

    let paymentDate = startDate;

    for (let i = 1; i <= loanTermMonths; i++) {
      // Incrementamos el mes y ajustamos para meses con menos días
      paymentDate = paymentDate.add(1, "month");

      const adjustedDate = paymentDate.date(startDate.date());
      if (adjustedDate.month() !== paymentDate.month()) {
        // Si el mes siguiente no tiene el mismo día, ajustamos al último día del mes
        paymentDate = paymentDate.endOf("month");
      } else {
        paymentDate = adjustedDate;
      }

      const interest = balance * monthlyInterestRate; // Interés de la cuota actual
      const capital = monthlyPayment - interest; // Capital de la cuota actual
      balance -= capital; // Reducción del saldo

      // Aseguramos que balance no sea negativo en el último pago
      const remainingBalance = balance < 0 ? 0 : balance;

      // Acumular el interés total
      totalInterest += interest;

      // Verificar si la cuota actual está en el array de pagos
      const isPaid = data?.payments?.includes(i.toString());

      // Agregamos los datos de la cuota al calendario de pagos
      schedule.push({
        cuota: i,
        payment: isPaid ? true : false,
        fechaPago: paymentDate.format("DD/MM/YYYY"), // Fecha en formato dd/mm/yyyy
        valorPrestamo: balance + capital, // Saldo antes del pago de esta cuota
        cuotaAPagar: monthlyPayment,
        capital: capital,
        interes: interest,
        saldoDeuda: remainingBalance,
      });
    }

    return { schedule, totalInterest };
  };

  const { schedule, totalInterest } = generatePaymentSchedule();

  const totalCredit = Number(totalInterest) + Number(data.ammount);

  return { schedule, totalInterest, totalCredit };
}
