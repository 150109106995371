import React, { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { formatNumber } from "../../../utils/format.js";
import Input from "./Input.js";
import { formatCurrency } from "../../../utils/formatCurrency.js";
import DownloadPDF from "../plan-de-pagos/downloadPDF.js";
import SaveSimulator from "../plan-de-pagos/saveSimulator.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../FirebaseConfig.ts";
import { formatCurrencyTable } from "../../../utils/formatCurrencyTable.js";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

function Simulador() {
  const [user, loading] = useAuthState(auth);
  const [fields, setFields] = useState({
    ammount: "5.000.000",
    tasa: "29.49",
    month: "24",
    dateStart: dayjs().format("YYYY-MM-DD"),
  });

  const loanAmount = fields.ammount.replace(/\./g, ""); // Monto del préstamo
  const annualInterestRate = fields.tasa / 100; // Tasa de interés anual en decimal
  const monthlyInterestRate = annualInterestRate / 12; // Tasa de interés mensual
  const loanTermMonths = fields.month; // Plazo del préstamo en meses
  const startDate = dayjs(fields.dateStart, "YYYY-MM-DD"); // Fecha de inicio del préstamo

  const monthlyPayment =
    loanAmount *
    (monthlyInterestRate /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths)));

  const generatePaymentSchedule = () => {
    let balance = loanAmount;
    const schedule = [];
    let totalInterest = 0; // Acumulador para el total de intereses

    let paymentDate = startDate;

    for (let i = 1; i <= loanTermMonths; i++) {
      // Incrementamos el mes y ajustamos para meses con menos días
      paymentDate = paymentDate.add(1, "month");

      const adjustedDate = paymentDate.date(startDate.date());
      if (adjustedDate.month() !== paymentDate.month()) {
        // Si el mes siguiente no tiene el mismo día, ajustamos al último día del mes
        paymentDate = paymentDate.endOf("month");
      } else {
        paymentDate = adjustedDate;
      }

      const interest = balance * monthlyInterestRate; // Interés de la cuota actual
      const capital = monthlyPayment - interest; // Capital de la cuota actual
      balance -= capital; // Reducción del saldo

      // Aseguramos que balance no sea negativo en el último pago
      const remainingBalance = balance < 0 ? 0 : balance;

      // Acumular el interés total
      totalInterest += interest;

      // Agregamos los datos de la cuota al calendario de pagos
      schedule.push({
        cuota: i,
        fechaPago: paymentDate.format("DD/MM/YYYY"), // Fecha en formato dd/mm/yyyy
        valorPrestamo: balance + capital, // Saldo antes del pago de esta cuota
        cuotaAPagar: monthlyPayment,
        capital: capital,
        interes: interest,
        saldoDeuda: remainingBalance,
      });
    }

    return { schedule, totalInterest };
  };

  const { schedule, totalInterest } = generatePaymentSchedule();

  const totalCredit =
    Number(totalInterest) + Number(fields.ammount.replace(/\./g, ""));

  const handleInputChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setFields({ ...fields, ammount: formattedValue });
  };

  return (
    <div className={`relative overflow-y-auto rounded-lg`}>
      <div className="flex justify-start gap-8 mt-8">
        <p className="text-left text-xl font-semibold mb-5">
          Simulador de Crédito
        </p>
        <div className="flex gap-3 items-center">
          <DownloadPDF
            data={{ ...fields, start_date: dayjs().format("YYYY-MM-DD") }}
          />
          {loading || !user ? (
            <div className="w-fit -mt-4 mb-3">
              <button
                className={`bg-robin rounded-lg py-1 px-2 text-black font-semibold mt-3 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Guardar Simulación
              </button>
            </div>
          ) : (
            <SaveSimulator
              data={{ ...fields, start_date: dayjs().format("YYYY-MM-DD") }}
              user={user}
              loading={loading}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 ">
        <div className="mb-5 bg-white text-center p-2 rounded-lg">
          <div className="grid grid-cols-2 gap-3 ">
            <div className="text-md mb-1">
              <Input
                label="Valor del préstamo"
                placeholder="$0"
                type="text"
                onChange={(e) => handleInputChange(e)}
                value={fields.ammount}
              />
            </div>
            <div className="text-md mb-1">
              <Input
                label="Tasa de interés anual"
                placeholder=""
                type="text"
                onChange={(e) => setFields({ ...fields, tasa: e.target.value })}
                value={fields.tasa}
              />
            </div>

            <div className="text-md mb-1">
              <Input
                label="Periodo de préstamo en meses"
                placeholder=""
                type="text"
                onChange={(e) =>
                  setFields({ ...fields, month: e.target.value })
                }
                value={fields.month}
              />
            </div>

            <div className="text-md mb-1">
              <Input
                label="Fecha de inicio del prestamo"
                placeholder=""
                type="date"
                onChange={(e) =>
                  setFields({ ...fields, dateStart: e.target.value })
                }
                value={fields.dateStart}
              />
            </div>
          </div>
        </div>
        <div className="mb-5 bg-white text-center p-2 rounded-lg flex flex-col items-center justify-center">
          <p className="text-md mb-1">
            Pago mensual:{" "}
            <b>{formatNumber(schedule[0]?.cuotaAPagar.toFixed(0))}</b>
          </p>
          <p className="text-md mb-1">
            Número de pagos: <b>{fields.month}</b>
          </p>
          <p className="text-md mb-1">
            Valor total de los intereses:{" "}
            <b>{formatNumber(totalInterest.toFixed(0))}</b>
          </p>
          <p className="text-md mb-1">
            Costo total del préstamo:{" "}
            <b>{formatNumber(totalCredit.toFixed(0))}</b>
          </p>
        </div>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-6 py-3">Cuotas</th>
            <th className="px-6 py-3">Fecha de pago</th>
            <th className="px-6 py-3">Valor préstamo</th>
            <th className="px-6 py-3">Cuota a pagar</th>
            <th className="px-6 py-3">Capital</th>
            <th className="px-6 py-3">Interés</th>
            <th className="px-6 py-3">Saldo deuda</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {schedule.map((row) => (
            <tr
              className="bg-white border-b  dark:border-gray-700"
              key={row.cuota}
            >
              <td className="px-6 py-4">{row.cuota}</td>
              <td className="px-6 py-4">
                {row.fechaPago === "Invalid Date" ? "" : row.fechaPago}
              </td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row.valorPrestamo)}
              </td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row?.cuotaAPagar)}
              </td>
              <td className="px-6 py-4">${formatCurrencyTable(row.capital)}</td>
              <td className="px-6 py-4">${formatCurrencyTable(row.interes)}</td>
              <td className="px-6 py-4">
                ${formatCurrencyTable(row.saldoDeuda)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Simulador;
