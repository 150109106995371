"use client";

import Spinner from "../../../components/Spinner";
import { formatNumber } from "../../../utils/format";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import ModalContainer from "../components/Table.js/ModalContainer.js";
import { Link } from "react-router-dom";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere";
import { db } from "../../../FirebaseConfig.ts";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import useInsertDoc from "../../../hooks/useInsertDoc.js";
import useUpdateDoc from "../../../hooks/useUpdateDoc.js";
import Trash from "../../../components/SVG/trash.js";

function Table({ user }) {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [showExcel, setShowExcel] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [finished, setFinished] = useState(false);
  const { handleInsert } = useInsertDoc();
  const { handleUpdate } = useUpdateDoc();
  const { data: dataUser, isFetching: fetchingUser } = useSnapshotWhere(
    "users",
    {
      filter: {
        firstCondition: "id",
        secondCondition: "==",
        thirdCondition: user.uid,
      },
    }
  );
  const [isFetching, setIsFetching] = useState(true);
  const [activeSearch, setActiveSearch] = useState(false);
  const [number, setNumber] = useState("");

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setItems(d);
    });
  };

  const categoriesCollection = collection(db, "requests");

  const fetchCategories = async () => {
    try {
      setIsFetching(true);
      setActiveSearch(true);
      const q = query(
        categoriesCollection,
        where("identity", "==", number),
        where("majorCompanyId", "==", dataUser[0]?.majorCompanyId)
      );
      const data = await getDocs(q);

      setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.log(error);

      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!fetchingUser) {
        getFirstData();
      }
    })();
  }, [fetchingUser]);

  const getFirstData = async () => {
    try {
      setIsFetching(true);
      const q = query(
        collection(db, "requests"),
        where("status", "==", "aprobado"),
        where("majorCompanyId", "==", dataUser[0]?.majorCompanyId)
      );

      const subscriber = onSnapshot(q, {
        next: (snapshot) => {
          const data = [];
          snapshot.docs.forEach((doc) => {
            data.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          setData(data);
          setIsFetching(false);
        },
      });

      return () => subscriber();
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  const handleInsertExcel = async () => {
    setUploading(true);
    for (const item of items) {
      const res = await handleInsert("requests", {
        ...item,
        ammount: `${item.ammount}`,
        knowMoney: true,
        majorCompanyId: dataUser[0]?.majorCompanyId,
        payments: [],
        cert_laboral: "",
        codeudor: "",
        comp_nomina: "",
        company: "",
        companyId: "",
        dec_renta: "",
        fot_cedula: "",
        status: "aprobado",
      });
      await handleUpdate("requests", res.id, { uuid: res.id });
    }
    setUploading(false);
    setFinished(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});

  const handleDeleteCredit = async (creditId) => {
    if (window.confirm("¿Está seguro de que desea eliminar este crédito?")) {
      try {
        await handleUpdate("requests", creditId, { status: "eliminado" });
        // Refresh the data after deletion
        getFirstData();
      } catch (error) {
        console.error("Error deleting credit:", error);
      }
    }
  };

  return (
    <>
      <div className="max-w-3xl mx-auto mb-6">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only "
        >
          Buscar
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Buscar por número de cédula"
            required
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <button
            onClick={() => fetchCategories()}
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Buscar
          </button>
        </div>

        {activeSearch ? (
          <button
            onClick={() => getFirstData()}
            className="text-white end-2.5 mt-3 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Limpiar
          </button>
        ) : (
          ""
        )}
        <div className="mt-6 bg-gray-100 rounded-lg p-2">
          {!showExcel ? (
            <button
              onClick={() => setShowExcel(true)}
              className="w-fit text-white end-2.5 mt-3 bottom-2.5 bg-green-700 mb-4 hover:bg-green-800 font-medium rounded-lg text-sm px-4 py-2"
            >
              Insertar a través de excel
            </button>
          ) : (
            <>
              <p>
                Insertar a través de excel
                <a
                  className="text-blue-700 ml-1"
                  target="_blank"
                  rel="noreferrer"
                  href="https://klazia.nyc3.digitaloceanspaces.com/plataforma/ejemplolendy_62d678ccea.xlsx"
                >
                  Descargar Ejemplo
                </a>
              </p>
              <div className="flex gap-4 justify-center items-center">
                <div className="flex-col flex justify-center items-center mt-3">
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                  />
                  <button
                    aria-disabled={
                      uploading || items.length === 0 ? true : false
                    }
                    disabled={uploading || items.length === 0 ? true : false}
                    onClick={() => handleInsertExcel()}
                    className={`w-fit text-white end-2.5 mt-3 bottom-2.5 bg-green-700 mb-4 hover:bg-green-800 font-medium rounded-lg text-sm px-4 py-2 ${
                      uploading || items.length === 0
                        ? "opacity-75 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    Subir Excel
                  </button>
                  {uploading ? <p>Subiendo...</p> : ""}
                  {finished ? (
                    <p>Se han subido los créditos satisfactoriamente</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalContainer
          currentRequest={currentRequest}
          majorCompanyId={dataUser[0]?.majorCompanyId}
          setCurrentRequest={setCurrentRequest}
        />
      </Modal>
      <div
        className={`relative shadow-md sm:rounded-lg ${
          isFetching ? "" : "overflow-x-auto"
        }`}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Nombres
              </th>
              <th scope="col" className="px-6 py-3">
                Compañia
              </th>
              <th scope="col" className="px-6 py-3">
                Celular
              </th>
              <th scope="col" className="px-6 py-3">
                Cédula
              </th>
              <th scope="col" className="px-6 py-3">
                Monto
              </th>
              <th scope="col" className="px-6 py-3">
                Estado
              </th>
              <th scope="col" className="px-6 py-3">
                Acción
              </th>
            </tr>
          </thead>

          <tbody className="bg-white">
            {isFetching ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : (
              data?.map((el, index) => {
                return (
                  <tr
                    className="odd:bg-white even:bg-gray-50  border-b"
                    key={el.id}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {`${el.name} ${el.lastName}`}
                    </th>
                    <td className="px-6 py-4">
                      {el.companyId ? (
                        <Link
                          to={`/plataforma/compania/${el.companyId}/${el.id}`}
                        >
                          <button className="bg-blue-700 rounded-lg px-2 py-1 text-white">
                            Ver
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="px-6 py-4">{el.cellphone}</td>
                    <td className="px-6 py-4">{el.identity}</td>
                    <td className="px-6 py-4">{formatNumber(el.ammount)}</td>
                    <td className="px-6 py-4">
                      {el.status === "pendiente" ? (
                        <div className="bg-robin rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Pendiente
                        </div>
                      ) : el.status === "rechazado" ? (
                        <div className="bg-red-500 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Rechazado
                        </div>
                      ) : (
                        <div className="bg-blue-500 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Aprobado
                        </div>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setCurrentRequest(el);
                        }}
                        className="font-medium text-blue-600 hover:underline"
                      >
                        Ver Solicitud
                      </button>
                      <Link
                        to={`/plataforma/simulador/${el.identity}/${el.uuid}`}
                      >
                        <button className="font-medium text-gray-600 hover:underline ml-2">
                          Ver Crédito
                        </button>
                      </Link>
                      <button
                        onClick={() => handleDeleteCredit(el.uuid)}
                        className="ml-2 text-red-600 hover:text-red-800"
                      >
                        <Trash size={18} />
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
